import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { EquipmentAPIs } from "../../../service/api-service";
import {
  setalertMessagetype,
  setalertMessage,
  setLoginModalOpen,
  setLogInLoading,
} from "redux/redux-slice";
import { IStoreValue } from "utils/types";
import ConfirmationModal from "./ConfirmationModal";
const loginstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 4,
};
const message = `Keeping part of all of the security deposit will delay the deposit of your funds for 48 hours to allow your renter time to dispute this. Are you sure you want to keep part of the deposit?`;
const SecurityRefundModal = ({
  isSecurityModalOpen,
  handleSecurityModalClose,
  refundableSecurityAmount,
  orderId,
  handleSecurityCompletion,
}) => {
  const dispatch = useDispatch();
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const [loading, setLoading] = useState(false);
  const [refundSecurityAmount, setRefundSecurityamount] = useState<string>("");
  const [refundSecurityamtreson, setRefundSecurityamtreson] =
    React.useState<string>("");

  const [showError, setShowError] = useState<boolean>(false);
  const [hideReasonField,setHideReasonField] = useState<boolean>(false);
  const [disableRefundBtn,setDisableRefundBtn] = useState<boolean>(true);

  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const handleConfirmationClose = () => setIsConfirmationOpen(false);
  const handleConfirmationOpen = () => setIsConfirmationOpen(true);

  refundableSecurityAmount = parseFloat(refundableSecurityAmount)?.toFixed(2);

  const HandleRefundAmount = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let val: string = event.target.value;

    if (val === "" || /^\d+(\.\d{0,2})?$/.test(val)) {
      if (parseFloat(val) > refundableSecurityAmount) {
        setDisableRefundBtn(true);
        setShowError(true);
      } else if (parseFloat(val) <= refundableSecurityAmount && showError) {
        setShowError(false);
      } 
      if (parseFloat(val) <= refundableSecurityAmount && disableRefundBtn) {
        setDisableRefundBtn(false);
      } else if(val === "") {
        setDisableRefundBtn(true);
      }
      if(parseFloat(val) === parseFloat(refundableSecurityAmount)) {
        setHideReasonField(true);
      } else if(hideReasonField) {
        setHideReasonField(false);
      }
      setRefundSecurityamount(val);
    }
  };

  const HandleSecurityReson = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const val: string = event.target.value;
    setRefundSecurityamtreson(val);
  };

  const handleSubmission = async () => {
    try {
      // Call the API to initiate the security refund
      const amount = parseFloat(refundSecurityAmount);
      const reason = refundSecurityamtreson.trim();
      setLoading(true);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const refundStatus = await EquipmentAPIs.RefundSecurity(
        {
          owner_id: AuthUserId.toString(),
          order_id: orderId.toString(),
          description: reason,
          amount: amount.toString(),
        },
        headers
      );

      // Process the API response
      if (refundStatus.type === "RXSUCCESS") {
        dispatch(setalertMessage(`$${amount} security refunded successfully.`));
        dispatch(setalertMessagetype("success"));
        setRefundSecurityamount("");
        setRefundSecurityamtreson("");
        handleSecurityCompletion();
        handleSecurityModalClose();
        handleConfirmationClose();
      } else {
        dispatch(
          setalertMessage(
            refundStatus.message ?? "Unable to process your Refund."
          )
        );
        dispatch(setalertMessagetype("error"));
      }
    } catch (err) {
      dispatch(
        setalertMessage("An error occurred while processing the refund.")
      );
      dispatch(setalertMessagetype("error"));
    } finally {
      setLoading(false);
    }
  };

  const securityRefund = async () => {
    // Extract and parse refund details
    const orderSecurity = refundableSecurityAmount;
    const amount = parseFloat(refundSecurityAmount);
    const reason = refundSecurityamtreson.trim();

    // Validation function to handle errors and set loading state
    const handleError = (message) => {
      dispatch(setalertMessage(message));
      dispatch(setalertMessagetype("error"));
      setLoading(false);
      return false;
    };

    // Validate the refund amount
    if (isNaN(amount) || amount < 0) {
      return handleError("Please fill in a valid security amount.");
    }

    // Validate the refund amount against the security deposit
    if (amount > orderSecurity) {
      return handleError(
        "Refund amount cannot be greater than the security deposit."
      );
    }

    // Validate reason for partial refunds
    if (amount < orderSecurity && !reason) {
      return handleError("Please enter the reason for the partial refund.");
    }
    if (amount < orderSecurity) {
      handleConfirmationOpen();
    } else {
      await handleSubmission();
    }
  };

  const handlePartialSecurityConfirmation = async () => {
    await handleSubmission();
  };

  return (
    <Modal
      keepMounted
      open={isSecurityModalOpen}
      onClose={handleSecurityModalClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box
        className="download-popups"
        sx={{
          ...loginstyle,
          // width: matchesMobileX ? "calc(100vw - 100px)" : 800,
          padding: "0px",
        }}
      >
        <Box className="login__main">
          <Box
            className="login__main-div edit-eqp"
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <IconButton
              className="cross-btn"
              onClick={handleSecurityModalClose}
            >
              <CloseIcon />
            </IconButton>
            <Box className="login__main-div__form popup-cmmn pupup-scroll edit-eqp-inner">
              <Typography variant="h5" style={{ color: "#faa61a" }}>
                Refund Security Deposit
              </Typography>
              <Typography variant="h6" sx={{ color: "#fff !important" }}>
                You can return up to ${refundableSecurityAmount}
              </Typography>

              <Box>
                {loading && (
                  <Box>
                    <Backdrop
                      sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                      }}
                      open={loading}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </Box>
                )}
                <FormControl fullWidth className="form-fields">
                  {showError && (
                    <Typography
                      sx={{
                        color: "red",
                        fontSize: "12px",
                        mb:1
                      }}
                    >
                      Amount must not exceed the security amount.
                    </Typography>
                  )}
                  <TextField
                    id="outlined-disabled"
                    hiddenLabel
                    onChange={HandleRefundAmount}
                    value={refundSecurityAmount}
                    variant="filled"
                    placeholder="Enter Security Amount"
                    className="popup-input"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      disableUnderline: true,
                      inputProps: {
                        maxLength: 12,
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
               {!hideReasonField && <FormControl fullWidth className="form-fields">
                  <FormControl>
                    <TextField
                      id="outlined-disabled"
                      hiddenLabel
                      onChange={HandleSecurityReson}
                      value={refundSecurityamtreson}
                      variant="filled"
                      placeholder="If not full refund, enter reason"
                      className="popup-input"
                      InputProps={{ disableUnderline: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </FormControl>}
                <Button
                  className="signin-btn"
                  type="button"
                  variant="contained"
                  sx={{ mt: 3, mb: 2, backgroundColor: "#139246",
                  opacity: disableRefundBtn?0.6:"normal",
                  pointerEvents: disableRefundBtn?"none":""
                }}
                  onClick={securityRefund}
                >
                  Refund
                </Button>{" "}
                &nbsp;
                <Button
                  className="signin-btn"
                  type="button"
                  variant="contained"
                  sx={{ mt: 3, mb: 2, backgroundColor: "red !important" }}
                  onClick={handleSecurityModalClose}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <ConfirmationModal
          isConfirmationOpen={isConfirmationOpen}
          handleConfirmationClose={handleConfirmationClose}
          onConfirm={handlePartialSecurityConfirmation}
          message={message}
          loading={loading}
        />
      </Box>
    </Modal>
  );
};

export default SecurityRefundModal;
